import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import "primereact/resources/themes/arya-blue/theme.css"; // Dark Theme
import "primereact/resources/primereact.min.css"; // Core CSS
import "primeicons/primeicons.css";
import "primeflex/primeflex.css"; // PrimeFlex CSS for responsive design

import { Menubar } from "primereact/menubar";
import { MenuItem } from "primereact/menuitem";

import Welcome from "./Welcome.tsx";
import Chat from "./Chat.tsx";

function App() {
  const [activeIndex, setActiveIndex] = useState(3);
  const navigate = useNavigate();

  const items: MenuItem[] = [
    { label: "My Profile", icon: "pi pi-home" },
    {
      label: "Questions?",
      icon: "pi pi-inbox",
      command: () => navigate("/chat"),
    },
    { label: "History", icon: "pi pi-inbox" },
  ];

  const start = (
    <span
      className="text-blue-500 cursor-pointer"
      style={{
        fontSize: "1.5rem",
        fontWeight: "bold",
        marginRight: "1rem",
      }}
      onClick={() => navigate("/")}
    >
      Mad Alice
    </span>
  );

  return (
    <div className="flex flex-column">
      <div className="flex align-items-center w-full">
        <Menubar
          model={items}
          start={start}
          className="mb-4 flex-wrap w-full"
        />
      </div>
      <div className="flex align-items-center">
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/chat" element={<Chat />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
