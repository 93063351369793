import "./Welcome.css";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "primereact/resources/themes/vela-blue/theme.css"; // Dark theme for PrimeReact
import "primereact/resources/primereact.min.css"; // Core CSS for PrimeReact
import "primeicons/primeicons.css"; // PrimeIcons CSS
import "primeflex/primeflex.css"; // PrimeFlex CSS for responsive design

import { Button } from "primereact/button";
import { Menubar } from "primereact/menubar";

const Welcome = () => {
  const navigate = useNavigate();

  const items = [
    { label: "Dashboard", icon: "pi pi-home" },
    { label: "Transactions", icon: "pi pi-chart-line" },
    { label: "Products", icon: "pi pi-list" },
    { label: "Messages", icon: "pi pi-inbox" },
  ];

  return (
    <div className="grid grid-nogutter surface-0 text-800">
      <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
        <section>
          <span className="block text-6xl font-bold mb-1">Mad Alice</span>
          <div className="text-6xl text-primary font-bold mb-3">
            Navigate hormone changes like a boss
          </div>
          <p className="mt-0 mb-4 text-700 line-height-3">
            Advanced AI technology meets personalized hormone health guidance.
            Understanding your body's changes has never been more intuitive.
          </p>

          <Button
            onClick={() => navigate("/chat")}
            label="What do you have questions about?"
            type="button"
            className="mr-3 p-button-raised"
          />
          <Button
            label="Build your profile"
            type="button"
            className="p-button-outlined"
          />
        </section>
      </div>
      <div className="col-12 md:col-6 overflow-hidden">
        <img
          src="/demo/images/blocks/hero/hero-1.png"
          alt="hero-1"
          className="md:ml-auto block md:h-full"
          style={{ clipPath: "polygon(8% 0, 100% 0%, 100% 100%, 0 100%)" }}
        />
      </div>
    </div>
  );
};

export default Welcome;
