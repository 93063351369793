import logo from './logo.svg';
import './Chat.css';
import React, { useEffect, useState } from 'react';
import { getAssistants, startConversation, getConversation, addMessage } from './services/ma_api';

import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';  // Choose a theme from PrimeReact
import 'primereact/resources/primereact.min.css';          // Core PrimeReact CSS
import 'primeicons/primeicons.css';                        // PrimeIcons

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import ConversationMessage from './components/ConversationMessage.tsx';

function Chat() {
  const [conversation_id, setConversationId] = useState('');
  const [thread_id, setThreadId] = useState('');
  const [conversation_start, setConversationStart] = useState('');
  const [conversation_response, setConversationResponse] = useState('');
  const [user_message, setUserMessage] = useState('');

  const handleButtonClick = async () => {
    try {
      setConversationResponse('Getting answer...')
      const add_message_response = await addMessage(conversation_id, thread_id, user_message)
      setConversationResponse(add_message_response.conversation.messages[0].message)
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const assistant_list_response = await getAssistants()
        const conversation_start_response = await startConversation()
        setConversationId(conversation_start_response.conversation.id)
        setThreadId(conversation_start_response.conversation.thread_id)
        setConversationStart(
          "Started conversation with assistant: " +
          assistant_list_response.assistants[0].name);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="app">
      <div className="content">
        <h1>{conversation_start || 'Loading...'}</h1>
        <ConversationMessage
          response={conversation_response}
        />
      </div>
      <footer className="footer">
        <InputTextarea
          value={user_message}
          onChange={(e) => setUserMessage(e.target.value)}
          placeholder="Type something..."
          className="footer-input"
        />
        <Button
          label="Submit"
          onClick={handleButtonClick}
          className="footer-button"
        />
      </footer>
    </div>
  );
}

export default Chat;
