import "./Welcome.css";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

const Welcome = () => {
  const navigate = useNavigate();

  return (
    <div className="flex align-items-left">
      <div className="p-4 text-left">
        <p className="text-4xl font-medium text-blue-200">
          Advanced AI technology meets personalized hormone health guidance.
        </p>
        <p className="text-xl font-medium text-blue-100">
          Understanding your body's changes has never been more intuitive.
        </p>
        <p className="text-xl font-medium text-blue-100">
          <Button
            onClick={() => navigate("/chat")}
            label="What do you have questions about?"
            type="button"
            className="p-button-raised"
          />
        </p>
        <p className="text-xl font-medium text-blue-100">
          <Button
            label="Build your profile"
            type="button"
            className="p-button-raised"
          />
        </p>
      </div>
    </div>
  );
};

export default Welcome;
